import React, { useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import BallotIcon from '@mui/icons-material/Ballot';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import FaseLuaModel from './fase-lua.model';
import imgLuaCheia from '../../assets/lua-cheia.png';
import imgLuaNova from '../../assets/lua-nova.png';
import imgPrimeiroQuarto from '../../assets/primeiro-quarto.png';
import imgUltimoQuarto from '../../assets/ultimo-quarto.png';

import './home.page.scss';
import ItemFaseLuaComponent from '../../components/item-fase-lua/item-fase-lua.component';
import { ListarFasesLuaService } from '../../services/api-fases-lua.service';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';

export default function HomePage() {
  const [openModal, setOpenModal] = React.useState(false);
  const [listFaseLua, setListFaseLua] = React.useState<Array<FaseLuaModel>>([]);
  const [itemAtual, setItemAtual] = React.useState<number>(0);

  useEffect(() => {
    ListarFasesLua();
  }, [])

  const NextSlide = () => {
    if (itemAtual < listFaseLua.length - 1) {
      setItemAtual(itemAtual + 1);
    }
  }

  const PrevSlide = () => {
    if (itemAtual > 0) {
      setItemAtual(itemAtual - 1);
    }
  }

  const ListarFasesLua = () => {
    LoadingService.sendLoadingAtivo(true);
    ListarFasesLuaService().then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListFaseLua(res.data);
        res.data.map((item: FaseLuaModel, index: number) => {
          if(item.isProximaLua === 'proxima'){
            setItemAtual(index);
          }
        });
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      <section className="page home">
        <h1>Fases da lua</h1>
        <div className="itens">
          {
            listFaseLua ? listFaseLua?.map((item, indexItem) => (
              <ItemFaseLuaComponent
                indexItem={indexItem}
                entraSai={indexItem === itemAtual ? "entra" : "sai"}
                item={item}
              />
            )) : <span>Nenhum item encontrado.</span>
          }
        </div>
        <div className="bts flex flex-sp">
          <Button className={itemAtual === 0 ? 'bt inativo' : 'bt'} onClick={PrevSlide}>
            <ArrowBackIosNewIcon />
          </Button>
          <Button className={listFaseLua.length - 1 === itemAtual ? 'bt inativo' : 'bt'} onClick={NextSlide}>
            <ArrowForwardIosIcon />
          </Button>
        </div>
        <Button className='bt-list' onClick={() => setOpenModal(true)} >
          <BallotIcon />
        </Button>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <ul className="itens">
              {
                listFaseLua ? listFaseLua?.map((item, indexItem) => (
                  <li key={indexItem} className={(item.isProximaLua + ' ') + (item.fase === "Super lua cheia" ? "super " : "") + (itemAtual === indexItem ? 'inativo' : '')}>
                    <Button className="bt" onClick={() => {
                      setItemAtual(indexItem);
                      setOpenModal(false);
                    }}>
                      <span className='flex flex-sp flex-cv'>
                        <span>
                          <img src={item.imgLua} alt="Lua" /> {item.fase}
                        </span>
                        <span>
                          {item.data}
                        </span>
                        <span>
                          {item.km} km
                        </span>
                      </span>
                    </Button>
                  </li>
                )) : <span>Nenhum item encontrado.</span>
              }
            </ul>
          </Box>
        </Modal>
      </section>
    </>
  );
}