import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    //baseURL: environment.apiMockUrl
    baseURL: environment.serverUrl
});

export async function ListarFasesLuaService() {
    let url = '/list-fases-lua';
    return await api.get(url, config);
}