import React from 'react';
import FaseLuaModel from '../../pages/home/fase-lua.model';
import imgTerra from '../../assets/terra.png';
import './item-fase-lua.component.scss';

function ItemFaseLuaComponent(props: { indexItem: number, entraSai: string, item: FaseLuaModel }) {

  return (
    <div className={"item-fase-lua " + props.entraSai + " " + props.item.isProximaLua}>
      {
        props.item.isProximaLua === 'proxima' ?
          <h5>próxima lua:</h5>
          :
          <h5>&nbsp;</h5>
      }
      <h4>{props.item.fase}</h4>
      <h3>{props.item.data}</h3>
      <img className='lua' src={props.item.imgLua} alt={props.item.fase} />
      <div className="terra">
        <i></i>
        <img src={imgTerra} alt="Terra" />
        <h6>
          <strong>{props.item.km} km</strong>
          <span>(Distância da Lua)</span>
        </h6>
      </div>
    </div>
  );
}

export default ItemFaseLuaComponent;
