
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HeaderComponent from './components/header/header.component';
import NotFoundPage from './pages/not-found/not-found.page';
import HomePage from './pages/home/home.page';
import EventosPage from './pages/eventos/eventos.page';
import SnackComponent from './components/snack/snack.component';
import LoadingComponent from './components/loading/loading.component';

function Routes() {

    return (
        <BrowserRouter>
            {/* <HeaderComponent /> */}
            <Switch>
                <Route exact path="/" component={HomePage} />
                {/* <Route exact path="/eventos" component={EventosPage} /> */}
                
                <Route component={NotFoundPage} />
            </Switch>
            <SnackComponent />
            <LoadingComponent />
        </BrowserRouter>
    )
}

export default Routes;