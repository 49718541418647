
import EnvironmentModel from "./environment.model"

let env: EnvironmentModel = {
    env: "dev",
    serverUrl: "http://localhost:8888/fases-da-lua/back/apis",
};

const getEnv = (urlAtual: string) => {
    if ((urlAtual).indexOf('hml') >= 0) { // hml
        env = {
            env: "hml",
            serverUrl: "https://touchpoint-backend-touch-point-hml.apps-nprd.sp.elocloud/",
        };
    } else if ((urlAtual).indexOf('fasesdalua.a7apps') >= 0) { // prod
        env = {
            env: "prd",
            serverUrl: "https://fasesdalua.a7apps.com/back/apis",
        };
    } else if ((urlAtual).indexOf('localhost') >= 0) { // local
        env = {
            env: "local",
            serverUrl: "http://localhost:8888/fases-da-lua/back/apis",
        };
    }
    
    return env;
}

export const environment: EnvironmentModel = getEnv(window.location.host);
